<template>
  <div>
    <div class="email-campaign">
      <!-- email-next -->
      <div class="email-next">
        <div class="list-btns">
          <ul>
            <li>
              <a class="step1" v-bind:class="step == 1 ? 'active' : ''" href="#"
                >Detail</a
              >
            </li>
            <!-- <li>
            <a class="step2" v-bind:class="step == 2 ? 'active' : ''" href="#"
              >Template</a
            >
          </li> -->
            <li>
              <a class="step3" v-bind:class="step == 3 ? 'active' : ''" href="#"
                >Customize Template</a
              >
            </li>
            <li>
              <a class="step4" v-bind:class="step == 4 ? 'active' : ''" href="#"
                >Preview</a
              >
            </li>
            <li>
              <a class="step5" v-bind:class="step == 5 ? 'active' : ''" href="#"
                >Recipients</a
              >
            </li>
            <span class="bar"></span>
          </ul>
        </div>
      </div>
      <!-- email-next -->
      <!-- email-step1 -->
      <div class="email-step1" v-bind:class="step == 1 ? 'active' : ''">
        <!-- <div class="email-campaign"> -->
        <!-- step1-area -->
        <div class="step1-area">
          <div class="email-deatils">
            <h3>Campaign Details</h3>
            <form action="">
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="campaignName">Campaign Name *</label>
                  <input
                    v-model="email_detail_.campaign_name"
                    type="text"
                    class="form-control"
                    id="campaignName"
                    placeholder="Enter Campaign Name"
                  />
                  <div
                    class="validate-error-message"
                    v-if="$vd.email_detail_.campaign_name.$hasError === true"
                  >
                    {{ $vd.email_detail_.$errors.campaign_name[0] }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group sub-emoji col-md-6">
                  <label for="Subject">Subject *</label>
                  <input
                    v-model="email_detail_.subject"
                    type="text"
                    class="form-control"
                    id="Subject"
                    placeholder="Subject here"
                  />
                  <div
                    class="validate-error-message"
                    v-if="$vd.email_detail_.subject.$hasError === true"
                  >
                    {{ $vd.email_detail_.$errors.subject[0] }}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- step1-area -->
        <!-- </div> -->
        <!-- Email campaign -->
        <div class="email-deatils"></div>
      </div>
      <!-- email-step2 -->
      <div class="email-step2" v-bind:class="step == 2 ? 'active' : ''">
        <!-- <div class="email-campaign"> -->
        <!-- step1-area -->
        <div class="step1-area">
          <div class="email-deatils">
            <h3>Choose Template</h3>
            <div class="email-template">
              <div class="row">
                <div class="col-md-3">
                  <div
                    class="nav flex-column nav-pills"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      v-for="(template, index) in sms_templates"
                      v-on:click.stop.prevent="temp_ = template"
                      v-bind:class="temp_.id == template.id ? 'active' : ''"
                      :key="index"
                      class="nav-link"
                      href="#"
                      role="tab"
                      >{{ template.name }}</a
                    >
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="tab-content">
                    <div
                      class="tab-pane fade show active"
                      role="tabpanel"
                      aria-labelledby="v-pills-touch-tab"
                    >
                      <div class="email-boxs" v-if="temp_.id">
                        <div>
                          {{ temp_ }}
                          <textarea
                            rows="10"
                            v-model="temp_.export_template"
                          ></textarea>
                        </div>
                        <br /><br />
                        <center>
                          <button
                            style="
                              margin-bottom: 5px;
                              margin: 0 auto;
                              display: inline;
                              width: 170px;
                              margin-right: 10px;
                            "
                            v-on:click="selectTemplate(temp_.id)"
                            class="rem-btn"
                          >
                            Select Template
                          </button>
                          <button
                            @click="deleteTemplate(temp_.id)"
                            class="rem-btn deletetemplate"
                            style="
                              margin-bottom: 5px;
                              display: inline;
                              width: 170px;
                              background: #ff4343;
                              border: 1px solid #ff470e;
                            "
                          >
                            Delete Template
                          </button>
                        </center>
                        <br /><br />

                        <!-- <div class="modal-footer email-footer">
                        <a
                          href="#"
                          style="background: #ff4343; color: white; border: red"
                          v-on:click.stop.prevent="deleteTemplate()"
                          class="add-btn"
                        >
                          Delete</a
                        >
                        <a href="#" @click="updateTitleModal" class="rem-btn">
                          Update Template Name</a
                        >
                        <a
                          href="#"
                          v-on:click.stop.prevent="updateTemplate()"
                          class="rem-btn"
                        >
                          Save</a
                        >
                      </div> -->
                      </div>
                      <div v-else>
                        <h4
                          style="
                            text-align: center;
                            margin-top: 122px;
                            margin-bottom: 122px;
                          "
                        >
                          Select text template for preview
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div
                v-if="!temp_.ut_content && loaded"
                class="no-template col-12"
              >
                <h4>No Template Found</h4>
                <p>Please create your first email template.</p>
                <router-link to="/add-template" class="rem-btn"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/23. Close.svg"
                    alt=""
                  />
                  Add New Template</router-link
                >
              </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- step1-area -->
        <!-- </div> -->
      </div>
      <!-- email-step3 -->
      <div class="email-step3" v-bind:class="step == 3 ? 'active' : ''">
        <!-- <div class="email-campaign"> -->
        <!-- step1-area -->
        <div class="step1-area">
          <div class="email-deatils">
            <h3>Customizing a Campaign Template</h3>
            <div class="d-flex">
              <textarea
                rows="10"
                class="form-control"
                v-model="temp_.body"
              ></textarea>
            </div>
          </div>
        </div>
        <!-- step1-area -->
        <!-- </div> -->
      </div>
      <!-- email-step4 -->
      <div class="email-step4" v-bind:class="step == 4 ? 'active' : ''">
        <!-- <div class="email-campaign"> -->
        <!-- step1-area -->
        <div class="step1-area">
          <div class="email-deatils">
            <h3>Preview Template</h3>
            <textarea
              readonly
              class="form-control"
              rows="10"
              v-model="temp_.body"
            ></textarea>
          </div>
        </div>
        <!-- step1-area -->
        <!-- </div> -->
      </div>
      <!-- email-step5 -->
      <div class="email-step5" v-bind:class="step == 5 ? 'active' : ''">
        <!-- <div class="email-campaign"> -->
        <!-- step1-area -->
        <div class="step1-area">
          <div class="email-deatils">
            <h3>Recipient List</h3>
            <form action="">
              <!-- <div class="form-row">
                                            <div class="form-group col-sm-6 col-md-6 col-lg-4">
                                                <label class="con-company currnt list-btn">
                                                    List
                                                    <input type="radio" checked="checked" name="radio" />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="form-group col-sm-6 col-md-6 col-lg-4">
                                                <label class="con-company Indivi-btn">
                                                    Individual contacts
                                                    <input type="radio" name="radio" />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div> -->

              <div class="list-tab">
                <div class="form-row">
                  <div class="form-group col-sm-6 col-md-6 col-lg-8">
                         <SingleSelect
                          :searchable="true"
                           id="a_usersadss"
                          textColumn="text"
                          placeholder="Choose campaigns list"
                         v-model="email_.campaign_list"
                         :options="campaignsList"
                        ></SingleSelect>
                    <div
                      class="validate-error-message"
                      v-if="$vd.email_.campaign_list.$hasError === true"
                    >
                      {{ $vd.email_.$errors.campaign_list[0] }}
                    </div>
                    <!-- <div class="lead-drop">
                                                              <label for="inputAddress23">
                                                                  Select lists <a class="subtr" href="#"><img class="img-fluid" src="../../assets/img/Subtract.svg" alt="" /></a>
                                                              </label>
                                                              <h4>CEO <img class="img-fluid" src="../../assets/img/Vector.svg" alt="" /></h4>
                                                          </div>
                                                          <div class="Lead-source select-list">
                                                              <div class="custom-control custom-checkbox">
                                                                  <input type="checkbox" class="custom-control-input" id="customCheck11" name="example1" />
                                                                  <label class="custom-control-label" for="customCheck11">Appointment Confirmation</label>
                                                              </div>
                                                              <div class="custom-control custom-checkbox">
                                                                  <input type="checkbox" class="custom-control-input" id="customCheck22" name="example1" />
                                                                  <label class="custom-control-label" for="customCheck22">Are you still in the Market?</label>
                                                              </div>
                                                              <div class="custom-control custom-checkbox">
                                                                  <input type="checkbox" class="custom-control-input" id="customCheck34" name="example1" />
                                                                  <label class="custom-control-label" for="customCheck34">CEO</label>
                                                              </div>
                                                              <div class="custom-control custom-checkbox">
                                                                  <input type="checkbox" class="custom-control-input" id="customCheck33" name="example1" />
                                                                  <label class="custom-control-label" for="customCheck33">Birthday Message</label>
                                                              </div>
                                                              <div class="custom-control custom-checkbox">
                                                                  <input type="checkbox" class="custom-control-input" id="customCheck35" name="example1" />
                                                                  <label class="custom-control-label" for="customCheck35">Close trxt</label>
                                                              </div>
                                                              <div class="custom-control custom-checkbox">
                                                                  <input type="checkbox" class="custom-control-input" id="customCheck36" name="example1" />
                                                                  <label class="custom-control-label" for="customCheck36">Congratulations</label>
                                                              </div>
                                                          </div> -->
                  </div>
                  <!-- <div class="form-group col-sm-6 col-md-6 col-lg-4">
                                                        <label for="inputPassword4"> </label>
                                                        <a class="new-list" href="#">Create new list</a>
                                                    </div> -->
                </div>

               
              </div>

              <!-- <div class="Individual-tab">
                                            <div class="form-row">
                                                <div class="form-group col-sm-6 col-md-6 col-lg-8">
                                                    <div class="lead-drop Individ">
                                                        <label for="inputAddress23">
                                                            Select lists <a class="subtr" href="#"><img class="img-fluid" src="../../assets/img/Subtract.svg" alt="" /></a>
                                                        </label>
                                                        <h4>CEO <img class="img-fluid" src="../../assets/img/Vector.svg" alt="" /></h4>
                                                    </div>
                                                    <div class="Lead-source select-list">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck01" name="example1" />
                                                            <label class="custom-control-label" for="customCheck01">Appointment Confirmation</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck02" name="example1" />
                                                            <label class="custom-control-label" for="customCheck02">Are you still in the Market?</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck03" name="example1" />
                                                            <label class="custom-control-label" for="customCheck03">CEO</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck04" name="example1" />
                                                            <label class="custom-control-label" for="customCheck04">Birthday Message</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck05" name="example1" />
                                                            <label class="custom-control-label" for="customCheck05">Close trxt</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck06" name="example1" />
                                                            <label class="custom-control-label" for="customCheck06">Congratulations</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-sm-6 col-md-6 col-lg-4">
                                                    <label for="inputPassword4"> </label>
                                                    <a class="new-list" href="#">Create new list</a>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="send-list">
                                                    <label for="">Sent to:</label>
                                                    <ul>
                                                        <li><img class="img-fluid" src="../../assets/img/check.svg" alt="" /> CEO</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> -->
              <div class="form-row">
                <div class="form-group col-md-6 col-lg-3">
                  <label class="con-company currnt send-tab">
                    Send now
                    <input
                      type="radio"
                      name="radio"
                      v-bind:value="false"
                      v-model="email_.schedule"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form-group col-md-6 col-lg-4">
                  <label class="con-company sche-tab">
                    Schedule for Later
                    <input
                      type="radio"
                      name="radio"
                      v-bind:value="true"
                      v-model="email_.schedule"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div
                  class="validate-error-message"
                  v-if="$vd.email_.schedule.$hasError === true"
                >
                  {{ $vd.email_.$errors.schedule[0] }}
                </div>

                <!-- <div class="form-group col-md-6 col-lg-5">
                                                  <label class="con-company zoon-tab">
                                                      Schedule based on time zones
                                                      <input type="radio" name="radio" />
                                                      <span class="checkmark"></span>
                                                  </label>
                                              </div> -->
              </div>

              <!-- send-now -->
              <div class="send-now" v-if="!email_.schedule">
                <div class="send-info">
                  <p>Your text will be sent immediately.</p>
                </div>
              </div>
              <!-- send-now -->

              <!-- sche-later -->
              <div class="sche-later" v-if="email_.schedule">
                <div class="send-info">
                  <p>Schedule a future time and date to publish your text.</p>
                  <h3>When do you want to send it out?</h3>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-5 col-lg-4">
                    <date-range-picker
                      :locale-data="{ format: 'mmmm dd, yyyy' }"
                      v-model="email_.schedule_date"
                      :single-date-picker="true"
                      :show-dropdowns="true"
                      :auto-apply="true"
                      :ranges="false"
                    ></date-range-picker>

                    <!-- <input type="date" class="form-control" placeholder="Schedule based on time zones" /> -->
                  </div>
                  <div class="form-group col-md-5 col-lg-4">
                    <div class="form-row">
                      <div class="form-group">
                        <vue-timepicker
                          v-model="email_.time"
                          format="hh:mm A"
                          :minute-interval="5"
                          close-on-complete
                          placeholder="hh:mm a"
                        ></vue-timepicker>
                      </div>
                      <p class="time-zoon mr-2">(UTC+03:00)</p>
                      <!-- <div class="form-group col-md-4">
                                                                    <select class="form-control" v-model="email_.tHour">
                                                                        <option value="">hours</option>
                                                                        <option v-for="(n, index) in 12" :key="index" v-bind:value="n">{{n}}</option>
                                                                    </select>
                                                                </div>
                                                                <div class="form-group col-md-4">
                                                                    <select class="form-control" v-model="email_.tMin">
                                                                        <option value="">minutes</option>
                                                                        <option v-for="(n, index) in 60" :key="index" v-bind:value="n-1 | numberStep('2')">{{n-1 | numberStep('2')}}</option>
                                                                    </select>
                                                                </div>
                                                                <div class="form-group col-md-4">
                                                                    <select class="form-control" v-model="email_.tDtype">
                                                                        <option value="am">am</option>
                                                                        <option value="pm">pm</option>
                                                                    </select>
                                                                </div> -->
                    </div>

                    <!-- <input type="time" class="form-control" /> -->
                  </div>
                  <!-- <div class="form-group col-md-2 col-lg-4">
                                                        <p class="time-zoon">(UTC+03:00)</p>
                                                    </div> -->
                </div>

                <!-- <div class="send-btn">
                                                  <a href="#">Sechedule Now</a>
                                              </div> -->
              </div>
              <!-- sche-later -->

              <!-- timezoon -->
              <div class="timezoon"></div>
              <!-- timezoon -->
            </form>
          </div>
        </div>
        <!-- step1-area -->
        <!-- </div> -->
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <a
            class="add-btn pull-left pr-5 pl-5"
            v-bind:class="step == 1 ? 'd-none' : ''"
            href="#"
            v-on:click="step == 3 ? (step = step - 2) : (step = step - 1)"
            >Back</a
          >
          <a
            v-if="!nextloader && step > 1"
            class="rem-btn pull-right pr-5 pl-5"
            href="#"
            v-on:click="nextStep"
          >
            <span v-if="step < 5">next</span>

            <span v-if="step == 5 && !btnLoader">Submit</span
            ><span v-if="btnLoader"
              ><span class="spinner-grow spinner-grow-sm"></span>
              Loading...</span
            >
          </a>

          <a
            v-if="step == 3"
            class="rem-btn pull-right pr-5 pl-5 savedesign"
            href="#"
            v-on:click="saveDesign"
            style="display: none"
          >
            <span>Save Template</span
            ><span v-if="btnLoader"
              ><span class="spinner-grow spinner-grow-sm"></span>
              Loading...</span
            >
          </a>
        </div>
      </div>
    </div>
    <div
      class="email-campaign"
      v-if="step == 1"
      v-bind:class="step == 1 ? 'active' : ''"
    >
      <div>
        <!-- Dealership -->
        <div class="accordion" id="accordionExample35">
          <div class="card">
            <div class="card-header" id="headingOne35">
              <h2 class="mb-0">
                <button
                  class="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne35"
                  aria-expanded="true"
                  aria-controls="collapseOne35"
                >
                  <div class="detais-title">
                    <h3>
                      <span
                        style="
                          font-weight: bold;
                          font-size: 20px;
                          line-height: 1.5;
                          color: #3151a1;
                          margin-bottom: 15px;
                        "
                      >
                        <img
                          class="img-fluid arrow-down"
                          src="../../assets/img/arrow-down.svg"
                          alt=""
                        />
                        Choose Template
                      </span>
                    </h3>
                  </div>
                </button>
              </h2>
            </div>

            <div
              id="collapseOne35"
              class="collapse show"
              aria-labelledby="headingOne35"
              data-parent="#accordionExample35"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <div
                      class="nav flex-column nav-pills"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <a
                        v-for="(template, index) in sms_templates"
                        v-on:click.stop.prevent="temp_ = template"
                        v-bind:class="temp_.id == template.id ? 'active' : ''"
                        :key="index"
                        class="nav-link"
                        href="#"
                        role="tab"
                        >{{ template.name }}</a
                      ><br />
                      <a @click="addnewtemplate" class="rem-btn"
                        >Add New Template</a
                      >
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="tab-content">
                      <div
                        class="tab-pane fade show active"
                        role="tabpanel"
                        aria-labelledby="v-pills-touch-tab"
                      >
                        <div class="email-boxs" v-if="temp_.id">
                          <div>
                            <iframe :srcdoc="temp_.body"></iframe>
                          </div>
                          <br /><br />
                          <center>
                            <button
                              style="
                                margin-bottom: 5px;
                                margin: 0 auto;
                                display: inline;
                                width: 170px;
                                margin-right: 10px;
                              "
                              v-on:click="selectTemplate(temp_.id)"
                              class="rem-btn"
                            >
                              Select Template
                            </button>
                            <button
                              @click="deleteTemplate(temp_.id)"
                              class="rem-btn deletetemplate"
                              style="
                                margin-bottom: 5px;
                                display: inline;
                                width: 170px;
                                background: #ff4343;
                                border: 1px solid #ff470e;
                              "
                            >
                              Delete Template
                            </button>
                          </center>
                          <br /><br />

                          <!-- <div class="modal-footer email-footer">
                        <a
                          href="#"
                          style="background: #ff4343; color: white; border: red"
                          v-on:click.stop.prevent="deleteTemplate()"
                          class="add-btn"
                        >
                          Delete</a
                        >
                        <a href="#" @click="updateTitleModal" class="rem-btn">
                          Update Template Name</a
                        >
                        <a
                          href="#"
                          v-on:click.stop.prevent="updateTemplate()"
                          class="rem-btn"
                        >
                          Save</a
                        >
                      </div> -->
                        </div>
                        <div v-else>
                          <h4
                            style="
                              text-align: center;
                              margin-top: 122px;
                              margin-bottom: 122px;
                            "
                          >
                            Select text template for preview
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div
                v-if="!temp_.ut_content && loaded"
                class="no-template col-12"
              >
                <h4>No Template Found</h4>
                <p>Please create your first text template.</p>
                <router-link to="/add-template" class="rem-btn"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/23. Close.svg"
                    alt=""
                  />
                  Add New Template</router-link
                >
              </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          v-if="!nextloader"
          class="rem-btn pull-right pr-5 pl-5"
          href="#"
          v-on:click="nextStep"
        >
          <span v-if="step == 1">next</span>
        </a>
        <img
          v-else
          style="width: 89px; float: right"
          src="../../assets/img/Spinner.gif"
          class="img-fluid"
        />
        <br /><br /><br /><br />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SingleSelect from "../helpers/singleSelect";
import VueDaval from "vue-daval";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import moment from "moment";
import $ from "jquery";

export default {
  name: "AddCampaigns",
  mixins: [VueDaval],
  components: {
    SingleSelect,
    DateRangePicker,
    VueTimepicker,
  },
  vdRules: {
    email_detail_: {
      campaign_name: { required: true },
      subject: { required: true },
    },
    email_: {
      campaign_list: { required: true },
      schedule: { required: true },
    },
  },
  created() {
    this.getCampaignsList();
    this.getSmsTemplates();
    $("iframe").css("max-width", "100%!important");
  },
  data() {
    return {
      temp_: {},
      nextloader: true,
      minHeight: "100%",
      step: 1,
      email_detail_: {
        campaign_name: "",
        subject: "",
        from_name: "",
      },
      email_: {
        same_reply_to: true,
        schedule: false,
        schedule_date: {},
        time: "",
        date: "",
        campaign_list: {},
      },
      templates: [],
      campaignsList: [],
      btnLoader: false,
      email_templates: [],
      sms_templates: [],
    };
  },
  methods: {
    addnewtemplate() {
      this.$vd.email_detail_
        .$validate()
        .then(() => {
          //console.log(this.email_)
          this.step = 3;
        })
        .catch(() => {
          console.log(this.$vd.email_detail_.$errors);
        });
    },
    preview() {
      this.$refs.emailEditor.editor.saveDesign((design) => {
        this.email_.design = design;
        console.log(this.email_.design);
      });
      this.$refs.emailEditor.editor.exportHtml((data) => {
        this.email_.template = data.html;
        console.log("export", this.email_.template);
        this.step = this.step + 1;
      });
    },
    deleteTemplate(id) {
      axios
        .get("template/delete/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.getTemplates();
            this.$toast.open({
              position: "top-right",
              message: res.data.message,
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectTemplate(id) {
      this.$vd.email_detail_
        .$validate()
        .then(() => {
          this.email_.template = this.temp_.export_template;
          this.step = 3;
          axios
            .get("selectTemplate/" + id, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              if (res.data.success) {
                this.email_.design = res.data.message;
                console.log(this.email_.design);
                console.log(res.data.message);
                this.$refs.emailEditor.editor.loadDesign(
                  JSON.parse(res.data.message)
                );
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$toast.open({
            position: "top-right",
            message: "Above fields are required",
            type: "info",
          });
          console.log(this.$vd.email_detail_.$errors);
        });
    },
    nextStep() {
      if (this.step == 1) {
        this.$vd.email_detail_
          .$validate()
          .then(() => {
            //console.log(this.email_)
            this.step = this.step + 2;
          })
          .catch(() => {
            console.log(this.$vd.email_detail_.$errors);
          });
      } else if (this.step == 2) {
        this.step = this.step + 1;
      } else if (this.step == 3) {
        this.step = this.step + 1;
      } else if (this.step == 4) {
        this.step = this.step + 1;
      } else if (this.step == 5) {
        this.btnLoader = true;
        this.$vd.email_
          .$validate()
          .then(() => {
            $.extend(this.email_, this.email_detail_);
            this.email_.date = moment(
              new Date(this.email_.schedule_date.startDate)
            ).format("YYYY-MM-DD");
            this.email_.template = this.temp_.body;
            axios
              .post("add_new_text_campaign", this.email_, {
                headers: {
                  Authorization: "Bearer " + this.$storage.get("auth").token,
                },
              })
              .then((res) => {
                if (res.data.success) {
                  this.btnLoader = false;
                  this.$router.push("/text-campaign");
                  console.log(res.data.success);
                }
              })
              .catch((err) => {
                this.btnLoader = false;
                console.log(err);
              });
          })
          .catch(() => {
            this.btnLoader = false;
            console.log(this.$vd.email_.$errors);
          });
      }
    },
    getCampaignsList() {
      axios
        .get("campaigns_list", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
             var dd = [];
              $.each(res.data.success, function (key, value) {
                dd.push({
                  id: value.id,
                  text: value.name,
                });
              });
              this.campaignsList = dd;
            
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editorLoaded() {
      //  axios
      //     .get("gettemplates", { headers: { Authorization: "Bearer " + this.$storage.get("auth").token } })
      //     .then((res) => {
      //         if (res.data) {
      //             console.log(res.data)
      //               this.$refs.emailEditor.editor.loadDesign(res.data);
      //         }
      //     })
      //     .catch((err) => {
      //         console.log(err);
      //     });
    },
    getTemplates() {
      axios
        .get("gettemplates", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data) {
            console.log(res.data.success);

            this.email_templates = res.data.message;
            if (this.email_templates.length > 0) {
              this.temp_ = this.email_templates[0];
            }

            this.nextloader = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSmsTemplates() {
      axios
        .get("get_smstemplates", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.sms_templates = res.data.success;
            this.temp_ = res.data.success[0];
            this.nextloader = false;
            // let vm = this;
            // setTimeout(() => {
            //   if (vm.sms_templates.length > 0) {
            //     vm.temp_ = vm.sms_templates[0];
            //   }
            //   this.loaded = true;
            // }, 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveDesign() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        this.email_.template = data.html;
      });
      this.$refs.emailEditor.editor.saveDesign((design) => {
        axios
          .post(
            "savetemplate",
            { design: design, exporttemplate: this.email_.template },
            {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            }
          )
          .then((res) => {
            if (res.data.success) {
              this.$toast.open({
                position: "top-right",
                message: "Template Saved",
              });
              this.getTemplates();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        console.log("exportHtml", data);
      });
    },
  },
};
</script>
<style scoped>
.arrow-down {
  bottom: 2px;
  position: relative;
  margin-right: 15px;
  transition: all 0.4s ease;
  transform: rotate(180deg);
}

.unlayer-editor {
  flex: 1;
  display: flex;
}

iframe {
  width: 100%;
  height: 500px;
}
</style>
<style>
.blockbuilder-branding {
  display: none !important;
}

.template {
  margin-bottom: 30px;
  cursor: pointer;
}

.savedesign {
  margin-right: 10px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link .active {
  color: #3151a1;
  background-color: #dbe5ff;
  border-top-left-radius: 10px;
}
.email-step3 .step1-area .email-deatils:before {
  display: none;
}
.vue-daterange-picker {
  position: static;
  display: block;
}
</style>
